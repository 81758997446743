<script setup lang="ts">
import { storeToRefs } from 'pinia'

import Snackbar from '~/components/shared/snackbar.vue'

import { useSnackbarStore } from '~/stores/snackbar'

const { queue: QUEUE } = storeToRefs(useSnackbarStore())

const snackbarCenterEl = useTemplateRef('snackbarCenter')
const hasCampaignFooter = ref(false)

watch(
  () => QUEUE.value.length,
  () => {
    const target = snackbarCenterEl.value
    if (!target) return

    checkForCampaignFooter()

    target.scrollTo({
      top: target.scrollHeight,
      left: 0,
      behavior: 'smooth',
    })
  },
)

function checkForCampaignFooter() {
  if (!import.meta.client) {
    hasCampaignFooter.value = false
    return
  }

  const campaignFooter = document.querySelector('.campaignFooter')
  if (campaignFooter) hasCampaignFooter.value = true
}
</script>

<template>
  <div
    ref="snackbarCenter"
    class="noScrollbar centerWrapper tw-fixed tw-left-lg tw-right-lg tw-z-[1000001] md:tw-right-0"
    :class="[hasCampaignFooter ? 'tw-bottom-7xl' : 'tw-bottom-lg']"
  >
    <TransitionGroup
      v-if="QUEUE && QUEUE.length"
      name="list"
      tag="div"
      class="tw-grid tw-grid-cols-1 tw-gap-lg"
    >
      <Snackbar v-for="elem in QUEUE" :key="elem.id" :config="elem" />
    </TransitionGroup>
  </div>
</template>

<style scoped lang="scss">
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
  width: max-content;
}

.centerWrapper {
  max-height: calc(100vh - theme('space.10'));
  width: fit-content;
}
</style>
